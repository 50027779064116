.max-lines {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
    font-size: 18px;
}

.cursor-pointer {
    cursor: pointer;
}

.img-thumb {
    padding: .25rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
    width: 90px;
    height: 90px;
}