button#pagination-first-page {
      transform: rotate(180deg);
}

button#pagination-previous-page {
      transform: rotate(180deg);
}

button#pagination-next-page {
      transform: rotate(180deg);
}

button#pagination-last-page {
      transform: rotate(180deg);
}

div.col nav > span {
      direction: ltr;
}

.white-space-normal {
      white-space: normal !important;
}
.comment-actions {
      position: absolute;
      top: 10px;
      left: 40px;
      text-align: left;
}
.reply-actions {
      padding-left: 10px;
      padding-top: 2px;
      text-align: left;
}
@media screen and (max-width: 1000px) {
      ul.nav.navbar-nav {
            height: 60px;
      }

      div.topbar-left {
            height: 63px !important;
      }

      div.topbar-left a.logo {
            height: 60px !important;
      }

      div.side-menu {
            top: 55px;
      }
}

@media screen and (min-width: 1001px) {
      div.side-menu {
            display: block !important;
            width: 250px;
      }

      div.content-page {
            margin-right: 250px !important;
      }
}
div[role="columnheader"] {
      font-size: 16px !important;
}
div[role="row"] {
      font-size: 16px;
      font-weight: normal;
}
div[role="columnheader"] > div {
      font-size: 16px;
      font-weight: bold !important;
}

div[role="columnheader"] > div {
      padding: 10px;
}

div > nav {
      font-size: 16px !important;
}

button.btn,
input.form-control,
textarea {
      font-size: 16px !important;
}

span.badge {
      font-size: 16px;
}
.btn.fs3rem {
      font-size: 3rem !important;
}
.btn.fs2rem {
      font-size: 2rem !important;
}
.w-200px {
      width: 200px !important;
}
.fs-05rem {
      font-size: 0.5rem !important;
}
.fs-1rem {
      font-size: 1rem !important;
}
.fs-075rem {
      font-size: 0.75rem !important;
}
.comment-book-thumbnail img {
      width: 100px;
      min-width: 100px;
      height: auto;
      max-height: fit-content;
      margin-top: -10px;
}

.comment-book-thumbnail p {
      max-width: 100px;
      text-align: center;
      font-size: 0.8rem;
      background-color: rgba(41, 41, 41, 0.85);
      color: #fff;
      border-radius: 5px 5px 0 0;
      pointer-events: none;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 2.3rem;
}
.comment-footer {
      display: flex;
}
.comment-footer i {
      font-style: normal;
}
.comment-footer button {
      padding: 2px 5px !important;
      font-size: 0.8rem !important;
}
.comment-footer button:not(:last-child) {
      margin-left: 10px;
}
.comment-reply-row {
      padding: 0 0 0 10px;
      width: 600px;
      text-align: right;
}
.audiohat-files .btn {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
      font-size: 2.8rem !important;
}

@media screen and (max-width: 600px) {
      footer.footer {
            font-size: 12px;
      }
}

@media screen and (min-width: 601px) {
      .mt-md-2rem {
            margin-top: 2rem !important;
      }
      .mt-md-2_5rem {
            margin-top: 2.5rem !important;
      }
      .mt-md-3rem {
            margin-top: 3rem !important;
      }
}
li > a.waves-effect {
      padding: 12px 8px !important;
}
